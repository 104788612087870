exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-breeder-profile-js": () => import("./../../../src/pages/breeder-profile.js" /* webpackChunkName: "component---src-pages-breeder-profile-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-dog-breed-js": () => import("./../../../src/pages/dog-breed.js" /* webpackChunkName: "component---src-pages-dog-breed-js" */),
  "component---src-pages-dog-profile-js": () => import("./../../../src/pages/dog-profile.js" /* webpackChunkName: "component---src-pages-dog-profile-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/FAQ.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-puppy-finder-js": () => import("./../../../src/pages/puppy-finder.js" /* webpackChunkName: "component---src-pages-puppy-finder-js" */),
  "component---src-pages-stud-finder-js": () => import("./../../../src/pages/stud-finder.js" /* webpackChunkName: "component---src-pages-stud-finder-js" */),
  "component---src-pages-terms-of-services-js": () => import("./../../../src/pages/terms-of-services.js" /* webpackChunkName: "component---src-pages-terms-of-services-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-breed-post-js": () => import("./../../../src/templates/breed-post.js" /* webpackChunkName: "component---src-templates-breed-post-js" */),
  "component---src-templates-puppy-post-js": () => import("./../../../src/templates/puppy-post.js" /* webpackChunkName: "component---src-templates-puppy-post-js" */),
  "component---src-templates-stud-post-js": () => import("./../../../src/templates/stud-post.js" /* webpackChunkName: "component---src-templates-stud-post-js" */)
}

